import { ChangeEvent } from 'react'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useBreakpointValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { semanticTokens } from 'composable/chakra/theme/foundations/semanticTokens'
import { useFormat } from 'helpers/hooks/useFormat'
import { FiSearch } from 'react-icons/fi'
import { IoMdCloseCircle } from 'react-icons/io'
import { useIntl } from 'react-intl'

export interface SearchInputProps {
  buttonProps?: ButtonProps & { ref?: React.RefObject<HTMLButtonElement> }
  clearSearchInput?: () => void
  clearSearchRef?: React.RefObject<HTMLButtonElement>
  closeSearch?: () => void
  closeSearchButtonRef?: React.RefObject<HTMLButtonElement>
  formSubmitHandle?: (e: ChangeEvent<HTMLFormElement>) => void
  inputGroupProps?: InputGroupProps
  inputProps?: InputProps
  containerProps?: FlexProps
  isOpen?: boolean
  overlayProps?: BoxProps
}

export const SearchInput = ({
  buttonProps,
  clearSearchInput,
  clearSearchRef,
  closeSearch,
  closeSearchButtonRef,
  containerProps,
  formSubmitHandle,
  inputGroupProps,
  inputProps,
  isOpen,
  overlayProps,
}: SearchInputProps) => {
  const intl = useFormat({ name: 'common' })
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Box position="relative" mt={{ base: isOpen ? '-100px' : 0, md: 0 }} width="full">
      <form onSubmit={formSubmitHandle}>
        <Flex alignItems="center" {...containerProps}>
          <InputGroup {...inputGroupProps}>
            <InputLeftElement pointerEvents="none" paddingLeft={'1rem'}>
              <Icon as={FiSearch} color="text-muted" boxSize="5" />
            </InputLeftElement>
            <VisuallyHidden>
              <label htmlFor="global-search-input">
                {intl.formatMessage({ id: 'global.search.input.placeholder' })}
              </label>
            </VisuallyHidden>
            <Input
              borderRadius={'6px'}
              id="global-search-input"
              paddingLeft={'3rem'}
              variant="filled"
              textStyle={'body-100'}
              placeholder={intl.formatMessage({
                id: 'global.search.input.placeholder',
              })}
              _placeholder={{
                textAlign: isOpen ? 'start' : 'center',
              }}
              border={isOpen ? '2px solid var(--chakra-colors-brand-primary)' : ''}
              {...inputProps}
              bgColor={isOpen ? 'white' : semanticTokens.colors.muted.default}
              _hover={{ bgColor: isOpen ? 'white' : '' }}
            />

            <InputRightElement width="fit-content">
              {!!inputProps?.value && (
                <IconButton
                  onKeyDown={(e) => {
                    // @ts-ignore
                    if (event.key === 'Enter') {
                      e.stopPropagation()
                      return
                    }
                  }}
                  aria-label="Clear"
                  colorScheme="red"
                  icon={<IoMdCloseCircle size={'20px'} />}
                  onClick={clearSearchInput}
                  ref={clearSearchRef}
                  variant="link"
                  w={'50px'}
                  minW={'auto'}
                />
              )}
              <Button
                aria-hidden={!isOpen || isMobile}
                borderLeftRadius={0}
                borderRightRadius={'6px'}
                height={'full'}
                hidden={!isOpen || isMobile}
                size="sm"
                type="submit"
                width="85px"
                {...buttonProps}
              >
                {intl.formatMessage({ id: 'global.search.button' })}
              </Button>
            </InputRightElement>
          </InputGroup>
          {isOpen && isMobile && (
            <Button
              color="blackAlpha.900"
              ref={closeSearchButtonRef}
              size="sm"
              textDecoration="none"
              textTransform="uppercase"
              variant="ghost"
              zIndex={inputGroupProps?.zIndex}
              onClick={closeSearch}
              p={0}
              ml={'10px'}
              width={'auto'}
            >
              {intl.formatMessage({ id: 'action.close' })}
            </Button>
          )}
        </Flex>
      </form>

      <Box
        borderRadius={{ base: 0, md: '6px' }}
        left="-16px"
        position="absolute"
        top={{ base: '-26px', md: '-16px' }}
        width="calc(100% + 32px)"
        {...overlayProps}
      />
    </Box>
  )
}
