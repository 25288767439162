import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import router from 'next/router'
import { InputProps } from '@chakra-ui/react'
import { useCombobox } from 'downshift'
import { SearchInput } from '../global-search-shared/search-input'
import { AlgoliaIndexNameResolver, AlgoliaQuerySuggestionsIndexNameResolver } from '../global-search-shared/utils'

const SearchPlaceholder = ({
  inputProps,
  clearSearchInput,
}: {
  inputProps?: InputProps
  clearSearchInput?: () => void
}) => (
  <SearchInput
    key="searchInput"
    buttonProps={{ disabled: true }}
    inputProps={inputProps}
    clearSearchInput={clearSearchInput}
  />
)

type AlgoliaGlobalSearchProps = {
  algoliaIndexNameResolver: AlgoliaIndexNameResolver
  algoliaQuerySuggestionsIndexNameResolver: AlgoliaQuerySuggestionsIndexNameResolver
}

const DynamicAlgoliaGlobalSearch = dynamic<AlgoliaGlobalSearchProps>(
  () => import('./algolia-global-search').then((_module) => _module.AlgoliaGlobalSearch),
  {
    loading: () => <SearchPlaceholder />,
  },
)

export const GlobalSearch = ({
  algoliaIndexNameResolver,
  algoliaQuerySuggestionsIndexNameResolver,
}: {
  algoliaIndexNameResolver: AlgoliaIndexNameResolver
  algoliaQuerySuggestionsIndexNameResolver: AlgoliaQuerySuggestionsIndexNameResolver
}) => {
  const [dynamicLoad, setDynamicLoad] = useState(false)
  const {
    getInputProps,
    setInputValue,
    reset: resetSearchBox,
  } = useCombobox({
    id: 'global-search',
    items: [],
  })

  const handleResetSearchBox = () => {
    resetSearchBox()
    router.push('/search')
  }

  useEffect(() => {
    // set the query from the url
    if (router.asPath.startsWith('/search')) {
      const query = router.query?.query?.toString()
      if (query) {
        setInputValue(query)
      }
    }
  }, [router])

  return dynamicLoad ? (
    <DynamicAlgoliaGlobalSearch
      algoliaIndexNameResolver={algoliaIndexNameResolver}
      algoliaQuerySuggestionsIndexNameResolver={algoliaQuerySuggestionsIndexNameResolver}
    />
  ) : (
    <SearchPlaceholder
      clearSearchInput={handleResetSearchBox}
      inputProps={{ ...getInputProps({ onFocus: () => setDynamicLoad(true) }) }}
    />
  )
}
