import { useEffect, useMemo, useRef } from 'react'
import { usePdp } from 'frontastic'
import { useAtgUser, useStore } from 'frontastic/contexts'

export const useWatchAndUpdateSelectedStoreData = () => {
  const { userSessionData } = useAtgUser()

  const { selectedStoreData, setSelectedStoreData } = useStore()

  const atgStoreKey = useMemo(
    () => userSessionData?.store?.displayStoreNumber?.split(' ')[1],
    [userSessionData?.store?.displayStoreNumber],
  )
  const currentStoreKey = useMemo(() => selectedStoreData?.key, [selectedStoreData?.key])

  const { getStoreByKey } = usePdp()

  const refGetStoreByKeyTimeout = useRef<NodeJS.Timeout>()

  /**
   * Fetch store data by key when the current store key is different from the ATG store key
   */
  useEffect(() => {
    const handleGetStoreByKey = async () => {
      try {
        if (atgStoreKey && currentStoreKey && atgStoreKey !== currentStoreKey) {
          const res = await getStoreByKey({ key: atgStoreKey })

          const storeData = res?.[0]

          if (storeData) {
            storeData.storeId = userSessionData?.store?.storeId
            storeData.storeNumber = userSessionData?.store?.storeNumber

            setSelectedStoreData(storeData)
          }
        }
      } catch (error) {
        console.error('Error fetching store data:', error)
      }
    }

    if (refGetStoreByKeyTimeout.current) {
      clearTimeout(refGetStoreByKeyTimeout.current)
    }

    /**
     * Delay the fetch store data by key to avoid multiple requests
     */
    refGetStoreByKeyTimeout.current = setTimeout(() => {
      handleGetStoreByKey()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atgStoreKey, currentStoreKey])
}
