import React, { useState } from 'react'
import {
  Box,
  Divider,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Center,
  Container,
} from '@chakra-ui/react'
import { StoreList } from './store-list'
import { StoreSearch } from './store-search'
import { StoresResponse } from './types'

export const StoreFinderPopup = ({ isOpen, onClose, shippingType }) => {
  const [storeData, setStoreData] = useState<StoresResponse>()
  const [showNoResults, setshowNoResults] = useState(false)
  const [isStoreSearch, setIsStoreSearch] = useState(false)
  const [locationCoordinate, setlocationCoordinate] = useState('')

  const storeHeading = {
    color: 'var(--semantic-text-primary, #15191C)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
  }

  const setDataOnClickOfFindStores = (data) => {
    setStoreData(data.storeData)
    setlocationCoordinate(data.addressLocation)
    setshowNoResults(data.showNoResults)
    setIsStoreSearch(data.isStoreSearch)
  }

  const onDrawerClose = (obj?) => {
    return onClose(obj)
  }

  return (
    <Modal isOpen={isOpen} onClose={onDrawerClose} size={'sm'}>
      <ModalOverlay />
      <ModalContent maxW={{ base: 375, md: 550 }}>
        <ModalHeader p={'12px 36px'}>
          <ModalCloseButton size={'sm'} fontSize={'sm'} mt={'2px'} />
          <Center h={'24px'} fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight={'1.5rem'}>
            <Text textStyle={'heading-desktop-100'}>My Store</Text>
          </Center>
        </ModalHeader>
        <Divider />
        <Container p={'24px 24px 12px'} borderBottom={'1px solid #E2E2E2'}>
          <Box fontSize={14}>
            <StoreSearch
              setDataOnClickOfFindStores={setDataOnClickOfFindStores}
              shippingType={shippingType}
            ></StoreSearch>

            {storeData && storeData?.results?.length > 0 ? (
              <>
                <Flex fontSize={'12px'}>
                  <Text>Displaying</Text>
                  <Text color={'#76797E'}>&nbsp; {storeData?.results?.length} Stores</Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex alignItems={'center'} fontSize={'12px'}>
                  <Text>Displaying</Text>
                  <Text color={'#76797E'}>&nbsp; 0 Stores</Text>
                </Flex>
              </>
            )}
          </Box>
        </Container>

        <ModalBody maxH={'450px'} overflow={'auto'} p={{ base: '1rem' }} pl={{ md: '1.5rem' }} pr={{ md: '1.5rem' }}>
          <StoreList
            addressLocation={locationCoordinate}
            showNoResults={showNoResults}
            onClose={onDrawerClose}
            storeHeading={storeHeading}
            isStoreSearch={isStoreSearch}
            setIsStoreSearch={setIsStoreSearch}
            isOpen={isOpen}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
