import { HStack, Text, useBreakpointValue } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { MyStore } from '../mystore/my-store'
import { useAtgUser } from 'frontastic/contexts'

export const MegaMenuDetailsBar = () => {
  const { data: cart } = useCart()
  const { formatMessage } = useFormat({ name: 'common' })
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const orderID = cart?.orderNumber ?? ''
  const { isInternationalUser } = useAtgUser()

  if (isInternationalUser) {
    return null
  }

  return (
    <HStack
      maxW="container.2xl"
      w={'100%'}
      m="auto"
      px={{ base: 4, lg: 10 }}
      py={2}
      spacing={8}
      bg="brand.muted"
      justifyContent={isMobile ? 'space-between' : ''}
    >
      <HStack spacing={1} cursor="pointer">
        <MyStore viewType={'drawer'} shippingType={{ shipToStore: true }} showMyStoreTitle={true} />
      </HStack>

      {!!orderID && (
        <HStack spacing={1}>
          <Text textStyle="body-75">{formatMessage({ id: 'megamenu.details.orderID' })}</Text>
          <Text textStyle={{ base: 'heading-mobile-75', lg: 'heading-desktop-75' }}>{orderID}</Text>
        </HStack>
      )}
    </HStack>
  )
}
